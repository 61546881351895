import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-not-finances',
  templateUrl: './seller-not-finances.component.html',
  styleUrls: ['./seller-not-finances.component.scss']
})
export class SellerNotFinancesComponent implements OnInit {

  seller = '';

  constructor() {
     this.seller = localStorage.getItem('sellerId');
   } 



  ngOnInit(): void {
  }

  goBack() {
    window.history.go(-1);
  }


}
