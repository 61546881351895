import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 


import { IframeListenerComponent } from './pages/iframe-listener/iframe-listener.component';
import { SecurityModule } from './security/security.module';
import { ExcelService } from './service/excel.service'
import { InterceptorInterceptor } from './security/interceptor.interceptor';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';

import localePt from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE  } from '@angular/material/core';

import { SellerNotFinancesComponent } from './shared/seller-not-finances/seller-not-finances.component';

registerLocaleData(localePt);

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    IframeListenerComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent,
    SellerNotFinancesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    CommonModule,
    SecurityModule,

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent,
    IframeListenerComponent,
  ]
})

export class AppModule { }
