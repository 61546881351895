import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './security/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent { 
  title = 'Projeto-Angular';
  devLocal = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private dialogRef: MatDialog
  ) {
    this.devLocal = environment.devLocal;
    if (this.devLocal) {
      localStorage.setItem('key', 'eyJraWQiOiJHVkJ3OUxVMGpSdlpUNWM1OUtycTJ3UlVGWlZzRjFVOWlyd0RLWHJIc05FPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5MDc3MTFiYy01MjdiLTQwZjYtYWUwNy1kNDZhOTg0N2U0NjgiLCJjb2duaXRvOmdyb3VwcyI6WyJmYXN0c2hvcC5hZG1pbi5jYWRhc3Ryb3NlbGxlciJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9SMmtlTEhNeFIiLCJjbGllbnRfaWQiOiI2YTNkdDdzZzFwZjV0YXVoM2c3dTNvb3J0MyIsImV2ZW50X2lkIjoiYzQ0MzU2YjAtM2I1MS00YTk0LWJmMTctMTllMzllOTIzNDEwIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY1ODE2NjI4MSwiZXhwIjoxNjU4MTY5ODgxLCJpYXQiOjE2NTgxNjYyODEsImp0aSI6IjZlMWFhMWNlLTk5NTYtNGU0Zi05N2M2LTg5NjQzNjJmMDhjOSIsInVzZXJuYW1lIjoiZmFzdHNob3AuYWRtaW4uY2FkYXN0cm9zZWxsZXJAbWFpbGluYXRvci5jb20ifQ.kNzrsjuyOVa_mcMuCUDwT7whUoWCPH7oihQ9VapnI9soM0iRvRDSyNfhQYAM4N0C-rjhmy3Wj13-VMvresI3LB_ub0of-gDIMEb8c_BciCHwEIP0uUQcMYtv3-vvwPKqBLjAuDZ_vN01p0BCCkReSJA3Bkf7uZdjC5ghDUqIlbJz5Hn6oRCPmkKXgPNMHdYwjzXcTnEJ_P5-j2H1e7MAXShr0m-IpWtyPg03KQWPWXp9gwCwv3c1WWgRvDOD1HWv9C9-54UltwywfyXppcD-T_08josUUWtXamxWyozGjwIXHzVDiGAXE_AWCjaRalzfLvPx6gYSn1HoJ8yKgZoUeA');
      localStorage.setItem('sellerId', 'leveros');
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.dialogRef.openDialogs.forEach((dialog) => dialog.close());
      });
  }
}
