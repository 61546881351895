import { NgModule } from '@angular/core'; 
import { CommonModule } from '@angular/common';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],  
  providers: [
    AuthService,
    JwtHelperService
  ],
  exports: [
    JwtModule
  ]
})
export class SecurityModule { }
