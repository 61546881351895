import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  static loadChange = new EventEmitter<boolean>();
 
  constructor() { }

  loadOn() {
    LoadingService.loadChange.emit(true);
  }

  loadOff() {
    LoadingService.loadChange.emit(false);
  }

}
