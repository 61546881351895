import { HttpClient } from '@angular/common/http'; 
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoSecurityService {

  url = '';
 
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.baseUrl;
   }

   getInfoUser() {
     return this.http.get(`${this.url}/securityAccess/userInfo/`);
   }

  getCnpjBySeller(sellerid: string) {
     return this.http.get(`${this.url}/securityAccess/userInfo/${sellerid}`);
   } 

} // close class
